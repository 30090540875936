import styled from 'styled-components';
import { MainTitle } from '@src/theme/commonElements';
export const ErrorInfoContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
export const ErrorTitle = styled(MainTitle) `
  color: ${({ theme }) => theme.palette.error[70]};
`;
export const ActionText = styled.p `
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  font-size: ${({ theme }) => theme.typography.fontSize.l};
  color: ${({ theme }) => theme.palette.text.primary};
`;
