import { createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle `
  body {
    min-height: 100vh;

    font-size: 20px;
    font-family: ${({ theme }) => theme.typography.fontFamily.primary};

    &, * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    }
  }
`;
