import styled from 'styled-components';
import ParcelLocker from '../assets/parcel-locker.jpg';
export const TopWrapper = styled.div `
  margin-top: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;
export const TopWrapperHight = styled.div `
  margin-top: ${({ theme }) => theme.spacing(5.5)};
`;
export const MainTitle = styled.h2 `
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  text-align: center;

  margin-bottom: ${({ theme }) => theme.spacing()};
`;
export const Emoji = styled.p `
  text-align: center;
  margin: ${({ theme }) => theme.spacing(4)} 0;
  font-size: 45px;
`;
export const ParcelLockerImage = styled.div `
  flex: 1;
  width: 100%;
  background-image: url(${ParcelLocker});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 30px;
`;
