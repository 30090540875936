import { jsx as _jsx } from "react/jsx-runtime";
import { useRoutes as useBaseRoutes } from 'react-router-dom';
import ComponentsWrapper from '@src/components/ComponentsWrapper';
import CloseDevice from '@src/pages/CloseDevice';
import Done from '@src/pages/Done';
import MainPage from '@src/pages/MainPage';
export function useRoutes() {
    return useBaseRoutes([
        {
            path: '/*',
            element: _jsx(ComponentsWrapper, {}),
            children: [
                {
                    path: 'key-locker',
                    element: _jsx(MainPage, {}),
                },
                {
                    path: ':flow/close-device',
                    element: _jsx(CloseDevice, {}),
                },
                {
                    path: ':flow/done',
                    element: _jsx(Done, {}),
                },
            ],
        },
    ]);
}
