import { useState } from 'react';
import { request } from '@src/api';
import { StorageKey } from '../types/storageKeys';
const NUMBER_OF_KEYLOCKER_STACKS = 4;
export function useAppData() {
    const [appData, setAppData] = useState(null);
    const [deviceStacks, setDeviceStacks] = useState([]);
    function setData(data) {
        sessionStorage.setItem(data.flow, JSON.stringify(data));
        setAppData(data);
    }
    function checkStacks(stacks) {
        if (stacks.length === NUMBER_OF_KEYLOCKER_STACKS) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, stack1, stack2, stack3] = stacks;
            const correctStacksSet = [stack2, stack3, stack1];
            sessionStorage.setItem(StorageKey.stacks, JSON.stringify(correctStacksSet));
            setDeviceStacks(correctStacksSet);
        }
    }
    async function getDeviceConfig(deviceUid, userUid, flow) {
        const savedData = sessionStorage.getItem(StorageKey.stacks);
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            setDeviceStacks(parsedData);
            return;
        }
        const { data: { stacks }, } = await request({
            url: `/device/${deviceUid}/configuration/view/`,
        });
        setData({
            deviceUid,
            userUid,
            flow,
        });
        checkStacks(stacks);
    }
    return {
        appData,
        deviceStacks,
        getDeviceConfig,
    };
}
