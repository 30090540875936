import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CODE_LENGTH } from './constants';
import { useInputActions } from './hooks/useInputActions';
import { CodeText, Form, FramesWrapper, SingleInput } from './styled';
export default function AccessCode({ value, isError, setValue, }) {
    const { onChangeHandler, onFocusHandler, onkeydownHandler } = useInputActions({
        setValue,
    });
    function getInputs() {
        const inputs = [];
        for (let i = 0; i < CODE_LENGTH; i++) {
            inputs.push(_jsx(SingleInput, { type: "text", inputMode: "numeric", pattern: "[0-9]*", value: value[i] || '', id: String(i), onFocus: onFocusHandler, onInput: onChangeHandler, onKeyDown: onkeydownHandler }, `input/${i}`));
        }
        return inputs;
    }
    return (_jsxs(_Fragment, { children: [_jsx(CodeText, { children: "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u043A\u043E\u0434 \u0434\u043E\u0441\u0442\u0443\u043F\u0443" }), _jsx(Form, { children: _jsx(FramesWrapper, { isError: isError, children: getInputs() }) })] }));
}
