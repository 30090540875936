import { useState } from 'react';
import { ErrorType } from '@src/api/types';
import { useNotifications } from '@src/contexts/NotificationsContext';
const OFFLINE = [
    'От халепа! Прилад не в мережі🥴',
    'Трясця! Поки прилад офлайн, можеш проклинати москалів😊',
    'Та щоб його! Не в мережі наш KeyLocker😐',
];
const INVALID_CODE = [
    'Не вгадав! Спробуй ще😏',
    'Ти дійсно не памʼятаєш пароль, чи просто не туди тикнув?🙈',
    'Успокойся. Не псіхуй. Вводь пароль правильно😉',
];
const OPEN_CELL = [
    'Бачу, що комірка відкрита. Треба зробити її закритою🙃',
    'Спочатку потрібно закрити всі комірки🧐',
    'Комірка шепче тобі: "Закрий мене"😏',
];
const OTHER = [
    'Та щоб його за ногу! Щось пішло не так😯',
    'Щось страшне та невідоме щойно трапилось😳',
    'Ой лишенько! Щось кепське трапилось🙁',
];
export function useErrorHandler() {
    const [isInvalidCode, setIsInvalidCode] = useState(false);
    const { setNotification } = useNotifications();
    function getRandomError(errors) {
        const errorsNumber = errors.length;
        const selectedErrorId = Math.ceil(Math.random() * errorsNumber) - 1;
        return errors[selectedErrorId];
    }
    function errorHandler(errorType) {
        switch (errorType) {
            case ErrorType.deviceOffline:
                setNotification({
                    value: getRandomError(OFFLINE),
                    status: 'error',
                });
                return;
            case ErrorType.invalidCode:
                setIsInvalidCode(true);
                setNotification({
                    value: getRandomError(INVALID_CODE),
                    status: 'error',
                });
                return;
            case ErrorType.openCell:
                setNotification({
                    value: getRandomError(OPEN_CELL),
                    status: 'error',
                });
                return;
            default:
                setNotification({
                    value: getRandomError(OTHER),
                    status: 'error',
                });
        }
    }
    return {
        isInvalidCode,
        setIsInvalidCode,
        errorHandler,
    };
}
