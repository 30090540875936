import { useLocation, useMatch } from 'react-router-dom';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
export function useHeaderSetting() {
    const owner = useMatch('/owner');
    const currier = useMatch('/currier');
    const { pathname } = useLocation();
    const { dataResStatus } = useApiContext();
    const isErrorPage = pathname.includes('error');
    const isMainPage = !!(owner || currier);
    const isHideChevron = 
    // TODO: This is temporary decision. Maybe in future we must delete
    // back btn
    true || isErrorPage || dataResStatus === 'pending' || isMainPage;
    return isHideChevron;
}
