import styled, { css } from 'styled-components';
function applyButtonStyles({ isActive, }) {
    return isActive
        ? css `
        pointer-events: all;
      `
        : css `
        pointer-events: none;
      `;
}
export const ButtonCommon = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 332px;
  height: 60px;

  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  color: ${({ theme }) => theme.palette.text.hightLight};

  background-color: ${({ theme }) => theme.palette.background.primary};
  border: none;
  border-radius: ${({ theme }) => theme.shape.borderRadius.l};

  & > a {
    display: contents;
    color: ${({ theme }) => theme.palette.text.hightLight};
  }

  ${applyButtonStyles};
`;
export const A = styled.a ``;
