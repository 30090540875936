import { useEffect, useState } from 'react';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { useCustomNavigate } from '@src/hooks/useCustomNavigate';
import { openDevice } from '@src/pages/MainPage/utils/openDevice';
import { useErrorHandler } from './useErrorHandler';
export function useOpenDevice(value) {
    const [isLoading, setIsLoading] = useState(false);
    const { appData, setOpenedCellDataHandler } = useApiContext();
    const { deviceUid, userUid } = appData;
    const { isInvalidCode, setIsInvalidCode, errorHandler } = useErrorHandler();
    const { navigateWithFlow } = useCustomNavigate();
    function openDeviceHandler() {
        setIsLoading(true);
        openDevice({
            deviceUid,
            userUid,
            placementCode: value.join(''),
        })
            .then((extra) => {
            setOpenedCellDataHandler(extra);
            navigateWithFlow('/close-device');
        })
            .catch(({ message: errorType }) => errorHandler(errorType))
            .finally(() => setIsLoading(false));
    }
    useEffect(() => {
        return () => setIsInvalidCode(false);
    }, [setIsInvalidCode, value]);
    return {
        isLoading,
        isInvalidCode,
        openDeviceHandler,
    };
}
