import styled, { css, } from 'styled-components';
function applyFramesStyle({ isError, }) {
    return isError
        ? css `
        input {
          color: ${({ theme }) => theme.palette.error[80]};
          box-shadow: inset 0px -4px 1px 0px rgba(228, 0, 1, 0.4);
        }
      `
        : css `
        input {
          box-shadow: none;
        }
      `;
}
export const Form = styled.form `
  position: relative;
  margin-top: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const FramesWrapper = styled.div `
  width: 290px;
  height: 100%;
  display: flex;
  justify-content: space-between;

  ${applyFramesStyle};
`;
export const SingleInput = styled.input `
  background: transparent;
  outline: none;
  text-align: center;

  display: flex;
  align-items: center;
  width: 42px;
  height: 60px;
  font-size: 32px;
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};

  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: ${({ theme }) => theme.shape.borderRadius.s};
  background-color: ${({ theme }) => theme.palette.background.hightLight};
`;
export const CodeText = styled.p `
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
`;
