import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@src/components/Button';
import { useApiContext } from '@src/contexts/ApiContext/ApiContext';
import { useCustomNavigate } from '@src/hooks/useCustomNavigate';
import { ParcelLockerImage, TopWrapperHight } from '@src/theme/commonElements';
import { Title } from './styled';
export default function CloseDevice() {
    const { navigateWithFlow } = useCustomNavigate();
    const { setOpenedCellDataHandler } = useApiContext();
    function closeCellHandler() {
        setOpenedCellDataHandler();
        navigateWithFlow('/done');
    }
    return (_jsxs(_Fragment, { children: [_jsx(TopWrapperHight, { children: _jsx(Title, { children: "\u0417\u0430\u043A\u0440\u0438\u0439\u0442\u0435, \u0431\u0443\u0434\u044C \u043B\u0430\u0441\u043A\u0430, \u043A\u043E\u043C\u0456\u0440\u043A\u0443" }) }), _jsx(ParcelLockerImage, {}), _jsx(Button, { onClick: closeCellHandler, children: "\u0414\u0430\u043B\u0456" })] }));
}
