import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { REGEXP_UID } from '../constants';
import { Flow } from '../types/context';
export function useUrlCheck() {
    const [initialData, setInitialData] = useState({
        flow: Flow.none,
        userUid: '',
        deviceUid: '',
        isAppPage: false,
    });
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        const pathData = pathname.split('/');
        const isOwnerPath = pathData[1]?.match(REGEXP_UID);
        const isDevicePath = pathData[2]?.match(REGEXP_UID);
        const isMainFlow = pathname.includes('key-locker');
        if ((isOwnerPath && isDevicePath) || isMainFlow) {
            setInitialData({
                flow: Flow.keyLocker,
                userUid: isOwnerPath?.[0] || '',
                deviceUid: isDevicePath?.[0] || '',
                isAppPage: isMainFlow,
            });
            return;
        }
        setInitialData((state) => ({
            ...state,
            flow: Flow.error,
        }));
        // We must check this once, when app is starting
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return initialData;
}
