import { useCallback, useEffect, useState } from 'react';
import { StorageKey } from '../types/storageKeys';
export function useOpenedCellData() {
    const [openedCellData, setOpenedCellData] = useState();
    const setOpenedCellDataHandler = useCallback((extra) => {
        if (extra) {
            sessionStorage.setItem(StorageKey.extra, JSON.stringify(extra));
        }
        else {
            sessionStorage.removeItem(StorageKey.extra);
        }
        setOpenedCellData(extra);
    }, []);
    useEffect(() => {
        const savedExtra = sessionStorage.getItem(StorageKey.extra);
        if (savedExtra)
            setOpenedCellData(JSON.parse(savedExtra));
    }, []);
    return {
        openedCellData,
        setOpenedCellDataHandler,
    };
}
