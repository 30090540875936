import styled from 'styled-components';
import ChevronSVG from '@src/assets/chevron.svg';
export const ByOmnicLogo = styled.p `
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeight[200]};
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
  letter-spacing: 0.2em;

  strong {
    font-family: ${({ theme }) => theme.typography.fontFamily.special};
    font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
    font-size: 18px;
    letter-spacing: 0;
  }
`;
export const ChevronIcon = styled(ChevronSVG) `
  position: absolute;
  left: ${({ theme }) => theme.spacing(3)};
`;
export const HeaderContainer = styled.header `
  height: 40px;
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;
