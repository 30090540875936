import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { Emoji } from '@src/theme/commonElements';
import { ErrorPageType } from './types';
import { ActionText, ErrorInfoContainer, ErrorTitle } from './styled';
export default function ErrorPage() {
    const location = useLocation();
    function getErrorPageData() {
        const state = location.state;
        if (state === ErrorPageType.noOrders) {
            return {
                title: 'У вас, поки що, немає замовлень',
                emoji: '🙄',
            };
        }
        return {
            title: 'Трясця! Щось трапилось зовсім негарне',
            emoji: '😐',
        };
    }
    const { title, emoji } = getErrorPageData();
    return (_jsxs(ErrorInfoContainer, { children: [_jsx(ErrorTitle, { children: title }), _jsx(Emoji, { role: "presentation", "aria-label": "Sad face", children: emoji }), _jsxs(ActionText, { children: ["\u041F\u043E\u0440\u0430\u0434\u0430 \u0434\u043D\u044F: \u0421\u043F\u0440\u043E\u0431\u0443\u0439 \u043F\u0456\u0437\u043D\u0456\u0448\u0435.", _jsx("br", {}), "\u0406 \u043D\u0435 \u0437\u0430\u0431\u0443\u0432\u0430\u0439: \u0442\u0438 - \u0442\u043E\u043F\u0447\u0438\u043A\uD83D\uDE0E"] })] }));
}
