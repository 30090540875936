/* eslint-disable no-console */
import axios from 'axios';
import { API_CUBE_CORE, HEADERS } from './constants';
import { identifyError } from './utils/identifyError';
export const request = async ({ method = 'GET', url, data, }) => {
    const axiosConfig = axios.create({
        baseURL: API_CUBE_CORE,
        headers: HEADERS,
    });
    try {
        const res = await axiosConfig.request({
            method,
            url,
            data,
        });
        return res.data;
    }
    catch (err) {
        console.error('HTTP REQUEST ERROR');
        console.error('STATUS:', err.response?.data?.status_code || err.response.status || 500);
        if (err.response?.data) {
            console.error('REASON:', err.response.data.message || 'unknown');
            if (err.response.data.ids) {
                console.error(err.response.data.ids);
                identifyError(err.response.data.ids);
            }
        }
        throw new Error(err);
    }
};
