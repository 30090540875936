import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@src/components/Button';
import Loader from '@src/components/Loader';
import { useOpenDevice } from '@src/pages/MainPage/components/hooks/useOpenDevice';
import { MainTitle, ParcelLockerImage, TopWrapper, } from '@src/theme/commonElements';
import AccessCode from './components/AccessCode';
export default function MainPage() {
    const [value, setValue] = useState(['']);
    const { isInvalidCode, isLoading, openDeviceHandler } = useOpenDevice(value);
    return (_jsxs(_Fragment, { children: [_jsxs(TopWrapper, { children: [_jsx(MainTitle, { children: "\u041B\u0430\u0441\u043A\u0430\u0432\u043E \u043F\u0440\u043E\u0441\u0438\u043C\u043E \u0443 KeyLocker!" }), _jsx(AccessCode, { isError: isInvalidCode, value: value, setValue: setValue })] }), _jsx(ParcelLockerImage, {}), _jsx(Button, { isActive: !isLoading, onClick: openDeviceHandler, children: isLoading ? _jsx(Loader, {}) : 'Відкрити KeyLocker' })] }));
}
