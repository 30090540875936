import { jsx as _jsx } from "react/jsx-runtime";
import Link from '../Link';
import Loader from '../Loader';
import { A, ButtonCommon } from './styled';
export default function Button({ to, href, children, isActive = true, isLoading = false, onClick, }) {
    const isActiveBtn = isActive ? isActive && !isLoading : isActive;
    const getChild = () => {
        let buttonChild;
        if (to) {
            buttonChild = _jsx(Link, { to: to, children: children });
        }
        if (href) {
            buttonChild = _jsx(A, { href: href, children: children });
        }
        if (!href && !to) {
            buttonChild = children;
        }
        return buttonChild;
    };
    return (_jsx(ButtonCommon, { onClick: onClick, isActive: isActiveBtn, children: isLoading ? _jsx(Loader, {}) : getChild() }));
}
