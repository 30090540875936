import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from 'styled-components';
import { ApiContext } from '@src/contexts/ApiContext/ApiContext';
import NotificationsContext from '@src/contexts/NotificationsContext';
import { useRoutes } from '@src/hooks/useRoutes';
import { defaultTheme } from '@src/theme/defaultTheme';
import { GlobalStyle } from '@src/theme/global';
export default function App() {
    const routes = useRoutes();
    return (_jsxs(ThemeProvider, { theme: defaultTheme, children: [_jsx(GlobalStyle, {}), _jsx(NotificationsContext, { children: _jsx(ApiContext, { children: routes }) })] }));
}
