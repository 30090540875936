import { useNavigate } from 'react-router-dom';
import { usePathPrefix } from './usePathPrefix';
export function useCustomNavigate() {
    const navigate = useNavigate();
    const prefix = usePathPrefix();
    function navigateWithFlow(to, options) {
        navigate(`/${prefix}${to}`, options);
    }
    return {
        navigateWithFlow,
    };
}
