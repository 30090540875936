import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useHeaderSetting } from './hooks/useHeaderSetting';
import { ByOmnicLogo, ChevronIcon, HeaderContainer } from './styled';
export default function Header() {
    const navigate = useNavigate();
    // TODO: Remove this feature in future
    const isHideChevron = useHeaderSetting();
    return (_jsxs(HeaderContainer, { children: [!isHideChevron && _jsx(ChevronIcon, { onClick: () => navigate(-1) }), _jsxs(ByOmnicLogo, { children: ["Innovated by ", _jsx("strong", { children: "OMNIC" })] })] }));
}
