import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useLayoutEffect, useMemo, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorPageType } from '@src/pages/ErrorPage/types';
import { useAppData } from './hooks/useAppData';
import { useOpenedCellData } from './hooks/useOpenedCellData';
import { useUrlCheck } from './hooks/useUrlCheck';
import { Flow, } from './types/context';
const Context = createContext(null);
export function ApiContext({ children }) {
    const [dataResStatus, setDataResStatus] = useState('idle');
    const initialData = useUrlCheck();
    const navigate = useNavigate();
    const { appData, deviceStacks, getDeviceConfig } = useAppData();
    const { openedCellData, setOpenedCellDataHandler } = useOpenedCellData();
    const { flow, userUid, deviceUid, isAppPage } = initialData;
    const value = useMemo(() => ({
        appData,
        dataResStatus,
        deviceStacks,
        openedCellData,
        setOpenedCellDataHandler,
    }), [
        appData,
        deviceStacks,
        dataResStatus,
        openedCellData,
        setOpenedCellDataHandler,
    ]);
    useLayoutEffect(() => {
        if (flow === Flow.none)
            return;
        if (flow === Flow.error) {
            navigate('/error', { state: ErrorPageType.loadingError });
            return;
        }
        if (dataResStatus === 'idle') {
            setDataResStatus('pending');
            Promise.all([getDeviceConfig(deviceUid, userUid, flow)])
                .then(() => {
                setDataResStatus('received');
                if (!isAppPage) {
                    navigate('/key-locker');
                }
            })
                .catch(() => {
                setDataResStatus('rejected');
                navigate('/error', { state: ErrorPageType.loadingError });
            });
        }
        // We must call this once, when we already received settings from url
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData, dataResStatus]);
    return _jsx(Context.Provider, { value: value, children: children });
}
export function useApiContext() {
    const value = useContext(Context);
    if (!value) {
        throw new Error('Please, use hook inside ApiContext');
    }
    return value;
}
